$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../../assets/fonts" !default;

$icon-logout: "\e919";
$icon-settings: "\e91a";
$icon-hamburgerclose: "\e907";
$icon-menu: "\e908";
$icon-archive: "\e916";
$icon-bubble: "\e917";
$icon-chat: "\e918";
$icon-check: "\e900";
$icon-chevron-down: "\e901";
$icon-chevron-up: "\e902";
$icon-close: "\e903";
$icon-custom: "\e904";
$icon-dashboard: "\e905";
$icon-dayshift: "\e906";
$icon-nightshift: "\e90a";
$icon-noonshift: "\e90b";
$icon-number: "\e90c";
$icon-public-no: "\e90d";
$icon-public-yes: "\e90e";
$icon-rate: "\e90f";
$icon-search: "\e910";
$icon-shifts: "\e911";
$icon-sun: "\e912";
$icon-time: "\e913";
$icon-timecards: "\e914";
$icon-view: "\e915";
$icon-exclaim: "\e909";
$icon-carshift: "\e91c";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?xcmvbn');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?xcmvbn#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?xcmvbn') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?xcmvbn') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?xcmvbn##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-logout {
  &:before {
    content: $icon-logout;
  }
}
.icon-settings {
  &:before {
    content: $icon-settings;
  }
}
.icon-hamburgerclose {
  &:before {
    content: $icon-hamburgerclose; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-archive {
  &:before {
    content: $icon-archive;
  }
}
.icon-bubble {
  &:before {
    content: $icon-bubble;
  }
}
.icon-chat {
  &:before {
    content: $icon-chat;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down;
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-custom {
  &:before {
    content: $icon-custom;
  }
}
.icon-dashboard {
  &:before {
    content: $icon-dashboard;
  }
}
.icon-dayshift {
  &:before {
    content: $icon-dayshift;
  }
}
.icon-nightshift {
  &:before {
    content: $icon-nightshift;
  }
}
.icon-noonshift {
  &:before {
    content: $icon-noonshift;
  }
}
.icon-number {
  &:before {
    content: $icon-number;
  }
}
.icon-public-no {
  &:before {
    content: $icon-public-no;
  }
}
.icon-public-yes {
  &:before {
    content: $icon-public-yes;
  }
}
.icon-rate {
  &:before {
    content: $icon-rate;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-shifts {
  &:before {
    content: $icon-shifts;
  }
}
.icon-sun {
  &:before {
    content: $icon-sun;
  }
}
.icon-time {
  &:before {
    content: $icon-time;
  }
}
.icon-timecards {
  &:before {
    content: $icon-timecards;
  }
}
.icon-view {
  &:before {
    content: $icon-view;
  }
}
.icon-exclaim {
  &:before {
    content: $icon-exclaim;
  }
}
.icon-carshift {
  &:before {
    content: $icon-carshift;
  }
}

